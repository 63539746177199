import React from 'react'

import Layout from 'layouts/index'
import Fade from 'react-reveal/Fade'

import TitleBox from 'components/TitleBox'

import {
  PageContainer,
  ContentWrapper,
  ContentText,
} from 'pageStyles/sobre-nos.styles'

import cirurgia from 'images/cirurgia_2.jpg'

const Cirurgia = () => {
  return (
    <Layout>
      <>
        <TitleBox title='Cirurgia' bg={cirurgia} />
        <PageContainer>
          <Fade>
            <ContentWrapper>
              <ContentText>
                <p>
                  Muitas são as situações onde temos que recorrer a
                  procedimentos cirúrgicos em nossos animais. Algumas delas, são
                  de extrema urgência, outras nem tanto. Porém todas devem ser
                  realizadas com a máxima segurança na questão da saúde para
                  eles. Um centro cirúrgico deve sempre estar bem equipado para
                  todo e qualquer imprevisto que possa ocorrer durante um
                  procedimento. Monitor multiparamétrico, desfibrilador,
                  ventilador mecânico, fornecimento constante de oxigênio são
                  ferramentas essenciais em qualquer centro cirúrgico.
                </p>

                <p>
                  Em nossa Clínica contamos com 2 salas de cirurgia com esses
                  equipamentos 24 horas disponíveis a toda e qualquer emergência
                  que possa acontecer.
                </p>
                <p>
                  Todos os materiais são higienizados e esterelizados por meio
                  de autoclavagem. São 2 aparelhos que realizam esse serviço
                  constantemente. Nossas salas são desinfetadas por meio de
                  ozonização, método comprovado de esterelização ambiental de
                  salas cirúrgicas hospitalares. Sempre utilizamos materiais
                  cirúrgicos escolhidos de forma criteriosa para garantir
                  sucesso cirúrgico, e que evitem ocorrências indesejadas de
                  reações pós-cirúrgicas.
                </p>
                <p>
                  Nossa equipe de cirurgiões estão em constante aprimoramento
                  técnico através de cursos e congressos, onde aprendem as mais
                  inovadoras técnicas cirúrgicas, trazendo o maior benefício
                  para os animais. Assim como nossa equipe de anestesiologistas
                  que estão com os mais novos protocolos anestésicos, trazendo
                  segurança no momento da anestesia em cada paciente.
                </p>
                <p>
                  São várias especialidades veterinárias que utilizam nossos
                  centro cirúrgicos: odontologia, oftalmologia, ortopedia,
                  neurologia, oncologia, tecidos moles, endoscopia.
                </p>
              </ContentText>
            </ContentWrapper>
          </Fade>
        </PageContainer>
      </>
    </Layout>
  )
}

export default Cirurgia
